<template>
  <p class="clearfix mb-0 footer-text">
    © {{ new Date().getFullYear() }} Nirvana Memorial Garden Pte. Ltd.
  </p>
</template>

<script>
export default {
  components: {
  },
}
</script>
